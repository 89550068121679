"use client";

import { useAppSelector } from "@/store";
import { selectIsShowLoginModal } from "@/store/slices/auth.slice";
import theme from "@/styles/theme";
import { Box, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Modal } from "@mui/material";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { LogInGridWrapper, MarketingWrapper } from "./login.styles";
import dynamic from "next/dynamic";

const LoginForm = dynamic(() => import("@/components/login/LoginForm"));

const LogInModal = () => {
  const { t } = useTranslation("login");
  const isShowLoginModal = useAppSelector(selectIsShowLoginModal);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isShowLoginModal);
  }, [isShowLoginModal]);

  return (
    <Modal open={open} data-testid="dti-login-modal" disableAutoFocus>
      <LogInGridWrapper container>
        <Grid
          item
          md={5.37}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
          data-testid="dti-marketing-stuff"
        >
          <MarketingWrapper>
            <Box sx={{ position: "relative" }} width="100%" height="100%">
              <Image
                src="/img/Analysing.svg"
                alt=""
                width={450}
                height={500}
                priority={true}
                style={{
                  height: "auto",
                  width: "100%",
                  bottom: 0,
                  position: "absolute",
                  left: 0,
                }}
              />
            </Box>
            <Box
              sx={{ padding: theme.spacing(2, 4) }}
              position="absolute"
              top={theme.spacing(4)}
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <Typography variant="hM" sx={{ color: "#E1E1E1" }}>
                {t("login.modal.marketing.headline")}
              </Typography>
              <Typography variant="bodyM" sx={{ color: "#B5B5B5" }}>
                {t("login.modal.marketing.subheadline")}
              </Typography>
            </Box>
          </MarketingWrapper>
        </Grid>
        <Grid item xs={12} md={6.63} data-testid="dti-login-form">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            sx={{
              padding: {
                xs: theme.spacing(0, 3, 3, 3),
                md: theme.spacing(2, 3, 3, 3),
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              sx={{
                height: {
                  xs: theme.spacing(7),
                  md: theme.spacing(4),
                },
                marginRight: {
                  xs: theme.spacing(-1),
                  md: 0,
                },
              }}
            />
            <Box display="flex" flex={1}>
              <LoginForm type="receiver" />
            </Box>
          </Box>
        </Grid>
      </LogInGridWrapper>
    </Modal>
  );
};

export default LogInModal;
